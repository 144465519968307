@import "../sass/typography";

nav.ah--navbar {
  li {
    a {
      min-height: 3rem;
      @extend .sans;
      color: #666;
      &:hover {
        color: #000;
      }
    }

    &.uk-active {
      font-weight: 700;
      border-bottom: 0.2em solid #000;
    }

    .uk-navbar-dropdown {
      background-color: #f1f0ef;
      label {
        cursor: pointer;
      }
    }
  }
}

.uk-light {
  nav.ah--navbar {
    li {
      a {
        color: #999;
        &:hover {
          color: #fff;
        }
      }

      &.uk-active {
        border-bottom: 0.2em solid #fff;
      }

      .uk-navbar-dropdown {
        background-color: #1f1f1f;
        label {
          color: #adadad;
        }
      }
    }
  }
}
