html {
  color: #333;
  background-color: #666;
}

.uk-background-muted {
  background-color: #fff;
}

.uk-background-secondary {
  background-color: #0c0c0c;
}

.uk-dark {
  a {
    color: #222;
    &:hover {
      color: #000;
    }
  }
}

.uk-inline {
  display: inline;
}
