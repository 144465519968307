@import "sass/base";
@import "sass/typography";

.App {
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.ah--spinner {
  color: #fff;
}

span {
  &.uk-badge {
    background-color: transparentize(grey, $amount: 0.25);
  }
}

.visuallyHidden {
  position: absolute;
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  white-space: nowrap;
}

.skip-link {
  left: -100%;

  &:focus {
    left: 50%;
    transform: translate(-50%, 0);
  }
}
