#toggler {
  align-self: center;
  margin-bottom: 0.2em;

  .ThemeToggle {
    width: 52px;
    height: 28px;
    border-radius: 50%;

    .ThemeToggle--input {
      &:checked + .ThemeToggle--label {
        background: #666;
        .ThemeToggle--toggle-handle {
          background: #333;
          transform: translateX(24px);
        }

        .ThemeToggle--moon {
          color: #fff;
        }
        .ThemeToggle--sun {
          color: #ccc;
        }
      }
    }

    .ThemeToggle--label {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 3em;
      background-color: #999;
      cursor: pointer;
    }

    .ThemeToggle--toggle-handle {
      left: auto;
      background-color: white;
      top: 3px;
      left: 3px;
      --size: 22px;
      width: var(--size);
      height: var(--size);
      border-radius: 50%;
      transition: transform 300ms ease-in-out,
        background-color 0.3s ease-in 0.15s, color 0.3s ease-in 0.15s;
    }

    .ThemeToggle--sun {
      color: #333;
      left: 7px;
      &.inMenu {
        left: unset;
      }
    }

    .ThemeToggle--moon {
      right: 7px;
      color: #ccc;
      &.inMenu {
        right: 14px;
      }
    }

    .ThemeToggle--toggle-handle-icon {
      top: 7px;
      width: 14px;
      height: 14px;
      svg {
        position: inherit;
      }
    }
  }
}
