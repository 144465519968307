footer {
  > div {
    width: 100%;
  }

  background-color: #fafafa;

  a {
    svg {
      fill: #666;
    }

    :hover {
      svg {
        fill: #000;
      }
    }
  }
}

.uk-light {
  footer {
    background-color: #222;

    a {
      svg {
        fill: rgba(255, 255, 255, 0.7);
      }

      :hover {
        svg {
          fill: #fff;
        }
      }
    }
  }
}
