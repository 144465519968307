@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;600&family=Raleway:wght@400;700&display=swap");
.serif {
  font-family: "Noto Serif", Helvetica, sans-serif;
}

.sans {
  font-family: "Raleway", Courier, serif;
  font-size: 0.95rem;
  line-height: 1.7;
  letter-spacing: 0.07rem;
  word-spacing: 0.08rem;
}

html,
body {
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.12rem;
  word-spacing: 0.16rem;
}
p + p {
  margin-top: 2rem;
}

[class^="recharts-"] {
  font-family: "Raleway", Courier, serif;
}

p,
a,
label,
input,
textarea,
blockquote,
figcaption,
cite {
  @extend .sans;
}

h1,
h2,
h3,
h4,
h5,
h6,
dl {
  @extend .serif;
  letter-spacing: 0.1em;

  dd {
    @extend .sans;
  }
}

h2 {
  &.accessibility-override {
    font-size: 1.5rem;
  }

  &.accessibility-case-intro {
    font-size: 1rem;
  }
}

h3 {
  &.accessibility-override {
    font-size: 1rem;
  }
}

.uk-heading-xlarge,
.uk-heading-large,
.uk-heading-medium {
  @extend .serif;
  font-weight: 600;
}

a {
  padding-bottom: 0.5em;
  color: inherit;
  &:link {
    text-decoration: none;
  }
}
